.scale {
  width: 100%;
  min-width: 295px;
  height: 2.75vw;
  min-height: 55px;
  border-bottom: solid #ccc;
  border-bottom-width: 0.05vw;
  border-bottom-width: max(0.05vw, 1px);
  position: relative;
  margin-bottom: 30px;
  margin-bottom: max(1.5vw, 30px);
  padding-top: 0.4vw;
  padding-top: max(0.4vw, 8px);
}
.scale_text {
  position: absolute;
  left: 0.5vw;
  left: max(0.5vw, 10px);
  color: #999;
  top: 0px;
  font-size: 0.75vw;
  font-size: max(0.75vw, 15px);
}
.rectangle {
  position: absolute;
  font-size: 0.7vw;
  font-size: max(0.7vw, 14px);
  right: 0.15vw;
  right: max(0.15vw, 3px);
  top: 1vw;
  top: max(1vw, 20px);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rectangle .small {
  width: 1.85vw;
  min-width: 37px;
  height: 1vw;
  min-height: 20px;
  margin-top: 0.8vw;
  margin-top: max(0.8vw, 16px);
  margin-left: 0px;
  border-radius: 0.2vw;
  border-radius: max(0.2vw, 4px);
  float: left;
  background-color: rgb(0, 0, 0);
  opacity: 0.2;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.rectangle .small.activ {
  background-color: #0364c1;
  opacity: 1;
}
.rectangle .small:hover {
  opacity: 0.5;
}
.rectangle .small.activ:hover {
  opacity: 1;
}

.rectangle .medium {
  width: 2.2vw;
  min-width: 44px;
  height: 1.2vw;
  min-height: 24px;
  margin-top: 0.6vw;
  margin-top: max(0.6vw, 12px);
  margin-left: 0.3vw;
  margin-left: max(0.3vw, 6px);
  border-radius: 0.25vw;
  border-radius: max(0.25vw, 5px);
  float: left;
  background-color: rgb(0, 0, 0);
  opacity: 0.2;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.rectangle .medium.activ {
  background-color: #0364c1;
  opacity: 1;
}
.rectangle .medium:hover {
  opacity: 0.5;
}
.rectangle .medium.activ:hover {
  opacity: 1;
}

.rectangle .large {
  width: 2.6vw;
  min-width: 52px;
  height: 1.4vw;
  min-height: 28px;
  margin-top: 0.4vw;
  margin-top: max(0.4vw, 8px);
  margin-left: 0.3vw;
  margin-left: max(0.3vw, 6px);
  border-radius: 0.3vw;
  border-radius: max(0.3vw, 6px);
  float: left;
  background-color: rgb(0, 0, 0);
  opacity: 0.2;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.rectangle .large.activ {
  background-color: #0364c1;
  opacity: 1;
}
.rectangle .large:hover {
  opacity: 0.5;
}
.rectangle .large.activ:hover {
  opacity: 1;
}

.rectangle .xlarge {
  width: 2.95vw;
  min-width: 59px;
  height: 1.6vw;
  min-height: 32px;
  margin-top: 0.2vw;
  margin-top: max(0.2vw, 4px);
  margin-left: 0.3vw;
  margin-left: max(0.3vw, 6px);
  border-radius: 0.35vw;
  border-radius: max(0.35vw, 7px);
  float: left;
  background-color: rgb(0, 0, 0);
  opacity: 0.2;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.rectangle .xlarge.activ {
  background-color: #0364c1;
  opacity: 1;
}
.rectangle .xlarge:hover {
  opacity: 0.5;
}
.rectangle .xlarge.activ:hover {
  opacity: 1;
}

.rectangle .xxlarge {
  width: 3.3vw;
  min-width: 66px;
  height: 1.8vw;
  min-height: 36px;
  margin-left: 0.3vw;
  margin-left: max(0.3vw, 6px);
  border-radius: 0.4vw;
  border-radius: max(0.4vw, 8px);
  float: left;
  background-color: rgb(0, 0, 0);
  opacity: 0.2;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.rectangle .xxlarge.activ {
  background-color: #0364c1;
  opacity: 1;
}
.rectangle .xxlarge:hover {
  opacity: 0.5;
}
.rectangle .xxlarge.activ:hover {
  opacity: 1;
}
