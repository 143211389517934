.select {
  position: relative;
  width: 100%;
  min-width: 295px;
  height: 3.5vw;
  min-height: 70px;
  margin-bottom: 30px;
  margin-bottom: max(1.5vw, 30px);
  padding-top: 0.4vw;
  padding-top: max(0.4vw, 8px);
  border-bottom: solid #ccc;
  border-bottom-width: 0.05vw;
  border-bottom-width: max(0.05vw, 1px);
  cursor: pointer;
  transition: all 0.5s ease;
}

.select::after {
  content: '<>';
  font-size: 1.1vw;
  font-size: max(1.1vw, 22px);
  font-family: 'Consolas', monospace;
  color: #0364c1;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 0.5vw;
  right: max(0.5vw, 10px);
  top: 1.65vw;
  top: max(1.65vw, 33px);
  padding-bottom: 2px;
  padding-bottom: max(0.15vw, 2px);
  border-bottom: solid #777;
  border-bottom-width: 0.05vw;
  border-bottom-width: max(0.05vw, 1px);
  position: absolute;
  pointer-events: none;
  transition: all 0.5s ease;
}

.select.dark:after {
  color: #fff;
}

.select .title {
  position: absolute;
  left: 0.5vw;
  left: max(0.5vw, 10px);
  color: #999;
  top: 0px;
  font-size: 0.75vw;
  font-size: max(0.75vw, 15px);
}

.select .options {
  position: absolute;
  font-size: 0.75vw;
  font-size: max(0.75vw, 15px);
  width: 100%;
  height: 2vw;
  min-height: 40px;
  top: 1.5vw;
  top: max(1.5vw, 30px);
  padding-left: 0.5vw;
  padding-left: max(0.5vw, 10px);
  border: none;
  background-color: #e7e7e7;
  color: #0364c1;
  border-radius: 0.35vw;
  border-radius: max(0.35vw, 7px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-word-break: normal;
  word-break: normal;
  transition: all 0.5s ease;
  cursor: pointer;
}

.select .options:hover {
  background-color: #d5d5d5;
  padding-left: 0.7vw;
  padding-left: max(0.7vw, 14px);
}

.select.dark .options {
  background-color: #1e1b30;
  color: #fff;
}

.select.dark .options:hover {
  background-color: #2a2545;
}

.select .options:active,
.select .options:focus {
  outline: none;
}

.blink {
  background-color: #ffb0b0 !important;
}
