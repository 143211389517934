/* Set global styles */
html {
  overflow-x: hidden;
  /* overflow-y: hidden; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: 400;
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
}

body {
  /* background-size: 21%; */
  background-attachment: fixed;
  position: fixed;
}

/* Remove scrollbar in both light and dark modes */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 0px;
}

.global {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #0364c1 #ffffff00;
}

/* Style the scrollbar in dark mode */
.global::-webkit-scrollbar {
  width: 0.4vw;
  min-width: 8px;
  background-color: transparent;
}

.global::-webkit-scrollbar-thumb {
  width: 0.4vw;
  min-width: 8px;
  background-color: #0364c1;
}

/* Style the scrollbar track in dark mode for mobile devices */
@media (max-width: 1200px) {
  .global::-webkit-scrollbar-track {
    margin-top: max(4vw, 80px);
    margin-bottom: max(1vw, 20px);
  }
}
/* .global.mobile::-webkit-scrollbar-track {
  margin-top: max(4vw, 80px);
  margin-bottom: max(1vw, 20px);
} */

/* Set styles for dark mode */
/* @media (prefers-color-scheme: dark) {
  body {
    background-color: #2a2545;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23322C52' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%2339325E'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
  }
} */

/* Set styles for light mode */
/* @media (prefers-color-scheme: light) {
  body {
    background-color: #d6e7f5;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23F2F4FF' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23FFFFFF'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
  }
} */

.responsive {
  display: block;
  position: absolute;
  width: 66%;
  height: 20px;
  margin: 0 17%;
  visibility: hidden;
}

@media (max-width: 1200px) {
  .responsive {
    width: calc(100% - 70px);
    margin: 0;
    right: 0;
  }
  .sidebar.open {
    width: 100%;
  }
  .global::-webkit-scrollbar-track {
    margin-top: max(4vw, 80px);
    margin-bottom: max(1vw, 20px);
  }
}
@media (min-width: 1201px) and (max-width: 1499px) {
  .responsive {
    width: 80%;
    margin: 0 10%;
  }
}

/* Blackout */
.blackoutDesktop,
.blackoutDesktopFolder,
.blackoutMobile {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
}
.blackoutDesktop {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 103;
}
.blackoutDesktopFolder {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
/* @media (max-width: 1200px) {
  .blackoutDesktop {
    z-index: 103;
  }
} */

/* .blackoutMobile {
  z-index: 103;
} */
.active {
  opacity: 1;
  pointer-events: all;
}

/* Indents */
.indent10,
.indent20,
.indent40 {
  width: 100%;
  height: max(0.5vw, 10px);
  min-height: 10px;
}
.indent20 {
  height: max(1vw, 20px);
}
.indent40 {
  height: max(2vw, 40px);
}

/* Horizontal line */
.line-hr {
  width: 70%;
  margin: 1vw auto;
  margin: max(1vw, 20px) auto;
  position: relative;
  text-align: center;
}
.line-hr::before {
  display: inline-block;
  border-top: max(0.05vw, 1px) solid #ccc;
  content: '';
  margin: 0 auto;
  position: absolute;
  height: 0;
  inset: 50% 0 0;
  z-index: -1;
}
.line-hr span {
  background: #fff;
  padding: 0 max(0.5vw, 10px);
  font-size: max(0.85vw, 17px);
  line-height: max(0.8vw, 16px);
  color: #999;
  text-align: center;
}
.line-hr.dark span {
  background: #1e1b30;
}

@media (max-width: 500px) {
  body {
    background-size: 61%;
  }
}
@media (min-width: 501px) and (max-width: 600px) {
  body {
    background-size: 57%;
  }
}
@media (min-width: 601px) and (max-width: 700px) {
  body {
    background-size: 53%;
  }
}
@media (min-width: 701px) and (max-width: 800px) {
  body {
    background-size: 49%;
  }
}
@media (min-width: 801px) and (max-width: 900px) {
  body {
    background-size: 45%;
  }
}
@media (min-width: 901px) and (max-width: 1000px) {
  body {
    background-size: 41%;
  }
}
@media (min-width: 1001px) and (max-width: 1200px) {
  body {
    background-size: 37%;
  }
}
@media (min-width: 1201px) and (max-width: 1400px) {
  body {
    background-size: 33%;
  }
}
@media (min-width: 1401px) and (max-width: 1600px) {
  body {
    background-size: 29%;
  }
}
@media (min-width: 1601px) and (max-width: 1800px) {
  body {
    background-size: 25%;
  }
}
@media (min-width: 1801px) and (max-width: 2000px) {
  body {
    background-size: 21%;
  }
}
@media (min-width: 2001px) and (max-width: 2500px) {
  body {
    background-size: 20.5%;
  }
}
@media (min-width: 2501px) and (max-width: 3000px) {
  body {
    background-size: 20%;
  }
}
@media (min-width: 3001px) and (max-width: 3500px) {
  body {
    background-size: 19.5%;
  }
}
@media (min-width: 3501px) and (max-width: 4000px) {
  body {
    background-size: 19%;
  }
}
@media (min-width: 4001px) and (max-width: 5000px) {
  body {
    background-size: 18.5%;
  }
}
@media (min-width: 5001px) and (max-width: 6000px) {
  body {
    background-size: 18%;
  }
}
@media (min-width: 6001px) and (max-width: 7000px) {
  body {
    background-size: 17.5%;
  }
}
@media (min-width: 7001px) and (max-width: 8000px) {
  body {
    background-size: 17%;
  }
}
@media (min-width: 8001px) {
  body {
    background-size: 16.5%;
  }
}
